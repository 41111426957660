import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets'
import './global'
import './my-components'
import {Integrations} from '@sentry/tracing'

Vue.config.productionTip = false

if (process.env.VUE_APP_TRACING_ORIGINS) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_TRACING_ORIGINS, /^\//],
      }),
    ],
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
