<template>
  <div class="wrapper">
    <app-sidebar />
    <main class="main" :class="{ 'main--full': !$store.state.sidebarOpen }">
      <app-header />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar'
import AppHeader from '@/components/AppHeader'

export default {
  components: {
    AppSidebar,
    AppHeader,
  },
}
</script>

<style lang="sass" scoped>
.wrapper
  flex-grow: 1
  display: flex
  width: 100%
  height: 100%

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
